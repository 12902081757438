
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import TrainningInstituteModal from '@/layout/header/partials/trainning_institute/trainning_institute_drawer.vue';
// import TargetListModal from "@/components/modals/forms/association/TargetListModal.vue";
import OrganizationModal from '@/components/modals/forms/settings/OrganizationModal.vue';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';
import { format } from 'date-fns';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'institute-list',
  components: {
    TrainningInstituteModal,
    Form,
    Datatable,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      actionApprove: false,
      actionEdit: false,
      action: false,
      data: {},
      employee: {
        company: '',
        status: '',
      } as any,
      association: [] as any,
       TpartnerData: [] as any,
      instituteTypes: [],
      industry: [],
      optionsTPartner: [] as any,
      divisions: [],
      companies: [] as any,
      subDistricts: [],
      empDistricts: [],
      employeeList: [],
      active_status: 0,
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '300px',
        },
        {
          name: 'SL',
          key: 'sl',
          sortable: false,
        },
         {
          name: 'Photo',
          key: 'photo',
          sortable: true,
        },
        {
          name: ' name',
          key: 'emp_name',
          sortable: true,
        },
        {
          name: 'Company Name',
          key: 'company_name',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Designation',
          key: 'designation',
          sortable: true,
          width: '200px',
        },
         {
          name: 'Present Address',
          key: 'present_address',
          sortable: true,
        },
        {
          name: 'Fathers Name',
          key: 'father_name',
          sortable: true,
        },
        {
          name: 'Mothers_name',
          key: 'mother_name',
          sortable: true,
        },

        {
          name: 'Phone',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        //  {
        //   name: 'DOB',
        //   key: 'dob',
        //   sortable: true,
        // },
        {
          name: 'NID',
          key: 'nid',
          sortable: true,
        },
        {
          name: 'Status',
          key: 'status',
          sortable: false,
        },
         {
          name: 'Created At',
          key: 'create',
          sortable: false,
        },
      ],
      adminCheck: false,
      loading: false,
      load: false,
      tranches: [],
      componentKey: 0,
      instituteInfos: false,
      TpartnerInfos:[] as any,
      showinstitutionList: false,
      showSearchBtn: false,
      showtrainingProviderNotice: false,
      api_url: 'http://localhost:8000',

      selectObj: {
        id: 'select',
        entity_short_name: 'Select All Training Partner',
        entity_name: 'Select All Training Partner',
      } as any,
      UnselectObj: {
        id: 'unselect',
        entity_short_name: 'Unselect All Training Partner',
        entity_name: 'Unselect All Training Partner',
      } as any,
      tempAssociation: [] as any,
      tempId: [] as any,
      associationData: [] as any,
    };
  },
  async created() {   
    await this.getCompany();
    await this.actioncheck();
    let company = VueCookieNext.getCookie('_company_id');
    if (company !== 'null') {
      this.employee.company = company;
       this.getEmployee();
    }
     this.emitter.on('employee-updated', async () => {
      this.getEmployee();
    });
  },
  
  methods: {
    async actioncheck() {
      let role = VueCookieNext.getCookie('_cpv_role_id');
      if (role == 1 || role == 2)
      {
        this.action = true;
         }
    },

        formatDate(dateString) {
      return dateString ? format(new Date(dateString), 'dd-MM-yyyy') : '';
    },
     async getCompany() {
       let company = VueCookieNext.getCookie('_company_id');
       let data = '';
    if(company !== 'null') {
        data = company;
      }
      
      await ApiService.get('company/list?company=' + data)
        .then((response) => {
          this.companies = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

      async getEmployee() {
          this.load = true;
      await ApiService.get('employee/list?company=' + this.employee.company + '&status='  + this.employee.status )
        .then((response) => {
            this.load = false;
          this.employeeList = response.data.data;
            this.componentKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
        this.showinstitutionList = true;
    },

    checkAdmin() {
      if (VueCookieNext.getCookie('_seip_entity_type') == 1004) {
        let user = VueCookieNext.getCookie('_seip_user');
        console.log(
          user.employee.entity_organogram[0].entitytyperole.role_title
        );
        if (
          user.employee.entity_organogram[0].entitytyperole.role_title ==
          'Admin'
        ) {
          this.adminCheck = true;
        }
      }
    },
  
   

   
  
    searchBtnActive() {
      this.showSearchBtn = true;
    },

    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
    edit(data) {
      this.emitter.emit('institute-edit-modal-data', data);
    },
    view(institute) {
      this.data = institute;
    },

    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('employee/delete/' + `${id}`)
            .then((response) => {
              if (response.data.status == 'error') {
              this.load = false;
              Swal.fire({
                title: 'Error!',
                html: response.data.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
              } else {
                Swal.fire('Deleted!', response.data.data, 'success').then(() => {
                   this.emitter.emit('employee-updated', true);
                });
              }
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Suspend(id) {
      Swal.fire({
        title: 'Are you sure you want to suspend it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, suspend!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get(
            'employee/status-change?institute_id=' + id + '&active_status=4'
          )
            .then((response) => {
              //this.courseInfo();
              Swal.fire('Suspended!', response.data.message, 'success');
              this.emitter.emit('employee-updated', true);
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Active(id) {
      Swal.fire({
        title: 'Are you sure you want to Active it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Activated!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get(
            'employee/status-change?employee_id=' + id + '&active_status=1'
          )
            .then((response) => {
              //this.courseInfo();
              Swal.fire('Activated!', response.data.message, 'success');
              this.emitter.emit('employee-updated', true);
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Inactive(id) {
      Swal.fire({
        title: 'Are you sure you want to Inactive it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Inactivate!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get(
            'employee/status-change?employee_id=' + id + '&active_status=3'
          )
            .then((response) => {
              //this.courseInfo();
              Swal.fire('Inactivated!', response.data.message, 'success');
              this.emitter.emit('employee-updated', true);
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
       PassReset(id) {
      Swal.fire({
        title: 'Are you sure you want to Reset Password?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get(
            'employee/reset-pass?employee_id=' + id 
          )
            .then((response) => {
              //this.courseInfo();
              Swal.fire('Reset SuccessFully!', response.data.message, 'success');
              this.emitter.emit('employee-updated', true);
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const InstituteSchema = [];
    return {
      InstituteSchema,
    };
  },
});
